<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Update User</h1>
                  <p>Expired Password</p>
                  <CInput
                    class="input"
                    v-model="username"
                    placeholder="Username"
                    readonly="readonly"
                    autocomplete="username email"
                    v-on:keyup.enter="focusNext"
                    id ="inputUsername"
                  >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    class="input"
                    placeholder="Existing Password"
                    :type="oldpasswordVisible ? 'text' : 'password'"
                    v-model="oldpassword"
                    id ="inputExistingPassword"
                    autocomplete="curent-password"
                    v-on:keyup.enter="focusNext"
                  >
                  <template #prepend-content>
                    <button type="button" @click='toggleoldPasswordVisibility' :arial-label='oldpasswordVisible ? "Hide password" : "Show password"' style="border:0px !important;padding:0px;">
                      <CIcon :name="oldpasswordVisible ? 'cil-lock-unlocked' : 'cil-lock-locked'"/>
                    </button>
                  </template>
                  </CInput>
                  <CInput
                    class="input"
                    placeholder="New Password"
                    :type="newpasswordVisible ? 'text' : 'password'"
                    v-model="newpassword"
                    id ="inputnewPassword"
                    autocomplete="curent-password"
                    v-on:keyup.enter="focusNext"
                  >
                  <template #prepend-content>
                    <button type="button" @click='togglenewPasswordVisibility' :arial-label='newpasswordVisible ? "Hide password" : "Show password"' style="border:0px !important;padding:0px;">
                		  <CIcon :name="newpasswordVisible ? 'cil-lock-unlocked' : 'cil-lock-locked'"/>
                    </button>
                  </template>
                  </CInput>
                  <CInput
                    class="input"
                    placeholder="Confirm Password"
                    :type="confirmpasswordVisible ? 'text' : 'password'"
                    v-model="confirmpassword"
                    id ="inputConfirmPassword"
                    autocomplete="curent-password"
                    v-on:keyup.enter="focusNext"
                  >
                  <template #prepend-content>
                    <button type="button" @click='toggleconfirmPasswordVisibility' :arial-label='confirmpasswordVisible ? "Hide password" : "Show password"' style="border:0px !important;padding:0px;">
                      <CIcon :name="confirmpasswordVisible ? 'cil-lock-unlocked' : 'cil-lock-locked'" />
                    </button>
                  </template>
                  </CInput>
                  <transition name="hint" appear>
                		<div v-if='passwordValidation.errors.length > 0 && !submitted' class='hints'>
                			<h2>Hints</h2>
                			<p v-for='error in passwordValidation.errors' style="margin-bottom:0px">{{error}}</p>
                      <br />
                		</div>
                	</transition>
                  <div class="matches" v-if='notSamePasswords'>
                		<p>Passwords don't match.</p>
                	</div>
                  <div class="already-used" v-if='sameCurrentPasswords'>
                		<p>Existing Passwords can't be used as New Password</p>
                	</div>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" :disabled="submitable" @click="passwordExp" class="px-4">Submit</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" @click="$router.push({ path : '/auth/login'})" class="px-4">Back</CButton>
                    </CCol>
                  </CRow><br/>
                  <CRow>
                   <CCol col="12" class="text-left">
                   <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                   </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <!--CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard-->
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

import AuthService from '@/api/AuthService.js';

    export default {
      name: 'Password',
      data() {
        return {
          rules: [
            { message:'One lowercase letter required.', regex:/[a-z]+/ },
            { message:"One uppercase letter required.",  regex:/[A-Z]+/ },
            { message:"15 characters minimum.", regex:/.{15,}/ },
            { message:"Symbol required.", regex:/[-!@$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/ },
            { message:"One number required.", regex:/[0-9]+/ }
          ],
          username: this.$route.query.user,
          submitable: false,
          oldpassword: '',
          newpassword: '',
          confirmpassword: '',
          oldpasswordVisible:false,
          newpasswordVisible:false,
          confirmpasswordVisible:false,
			    submitted:false,
          msg: '',
          seen: false,
          color: ''
        };
      },
      methods: {
        focusNext(e) {
            const inputs = Array.from(e.target.form.querySelectorAll('input[class="input"]'));
            const index = inputs.indexOf(e.target);

            if(index === 1){
              this.passwordExp();
            } else if (index < inputs.length) {
              inputs[index + 1].focus();
            }
        },
        resetPasswords () {
    			this.newpassword = ''
    			this.confirmpassword = ''
    			this.submitted = true
    			setTimeout(() => {
    				this.submitted = false
    			}, 2000)
    		},
    		toggleoldPasswordVisibility () {
    			this.oldpasswordVisible = !this.oldpasswordVisible
    		},
    		togglenewPasswordVisibility () {
    			this.newpasswordVisible = !this.newpasswordVisible
    		},
    		toggleconfirmPasswordVisibility () {
    			this.confirmpasswordVisible = !this.confirmpasswordVisible
    		},
        async getPasswExp(){
            if(!this.$route.query.hasOwnProperty('user') || !localStorage.hasOwnProperty('hash_pass')){
                this.$router.push({ path: '/auth/login' });
            }
            this.oldpassword = atob(localStorage.getItem('hash_pass'));
        },
        async passwordExp() {
          if (!this.oldpassword || !this.newpassword ) {
              this.msg = "Old Password and New Password Required";
              this.color = 'warning'; this.seen  = true;
          } else {
              var mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
              var passthing = this;
              try {
                  const parameters = { userName:this.username, existingPassword: this.oldpassword, newPassword: this.newpassword };
                  const resp = await AuthService.updateExpiryPassword(parameters);
                  if(resp.data.status=="OK"){
                        localStorage.removeItem('hash_pass');
                        this.$router.push({ path: '/auth/login' });
                  } else {
                    this.msg = resp.data.message;
                    this.color = 'warning'; this.seen  = true;
                  }
              } catch (error) {
                console.log(error);
              }
          }
        }
      },
      created: function(){
        this.getPasswExp()
      },
      computed: {
    		notSamePasswords () {
    			if (this.passwordsFilled) {
    				return (this.newpassword !== this.confirmpassword)
    			} else {
    				return false
    			}
    		},
    		sameCurrentPasswords () {
    			if (this.passwordsFilled) {
    				return (this.oldpassword === this.newpassword)
    			} else {
    				return false
    			}
    		},
    		passwordsFilled () {
    			return (this.password !== '' && this.confirmpassword !== '')
    		},
    		passwordValidation () {
    			let errors = []
          if(this.newpassword !== ''){
      			for (let condition of this.rules) {
      				if (!condition.regex.test(this.newpassword)) {
      					errors.push(condition.message)
      				}
      			}
    			}
    			if (errors.length === 0) {
            this.submitable = (this.newpassword !== '' && this.confirmpassword !== '') ? false : true;
    				return { valid:true, errors }
    			} else {
            this.submitable = true;
    				return { valid:false, errors }
    			}
    		}
    	}
    };

</script>
